body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body, #root {
  height: 100vh !important;
  width: 100vw !important;
  overflow: hidden;
}

/* width */
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  cursor: pointer !important;
}

a.active {
  background: #ecebeb !important;
  /*border-radius: 10px;*/
}

.create-btn {
  background: rgb(123,47,133) !important;
  background: linear-gradient(11deg, rgba(123,47,133,1) 35%, rgba(255,0,232,1) 100%) !important;
}

.blured {
  position: absolute;
  /*background: #888888;*/
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
  /*filter: blur(1.5rem);*/
  z-index: 1;
}

.call {
    -webkit-animation: gradient 10s ease infinite;
    -moz-animation:    gradient 10s ease infinite;
    -o-animation:      gradient 10s ease infinite;
    -ms-transition:    gradient 10s ease infinite;
    animation: gradient 10s ease infinite;
    position: fixed;
    background: linear-gradient(-45deg, #ee7752, #7fd24d, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(4px);
    /*filter: blur(1.5rem);*/
    z-index: 10000;
}

.recording-button.not-active {
    background: #e3e3e3 !important;
}

.recording-button.active {
    -webkit-animation: gradient 10s ease infinite;
    -moz-animation:    gradient 10s ease infinite;
    -o-animation:      gradient 10s ease infinite;
    -ms-transition:    gradient 10s ease infinite;
    animation: gradient 10s ease infinite;
    background: linear-gradient(-45deg, #ee7752, #7fd24d, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    backdrop-filter: blur(4px);
    color: #FFF
    /*filter: blur(1.5rem);*/
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}


@-webkit-keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@-moz-keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}


@-o-keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}


@-ms-keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}