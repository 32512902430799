.typing-dots {
  display: inline-block;
}

.typing-dots span {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin-right: 6px;
  background-color: #FFF;
  border-radius: 50%;
  animation: typingDots 1.5s infinite linear;
}

.typing-dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-dots span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes typingDots {
  0%, 20% {
    transform: translateY(5px);
  }
  50%, 80% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(5px);
  }
}